import "./Reward.scss";
import MainLayout from "../../layouts/MainLayout";
import { ROUTES } from "../../lib/consts";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "../../api";
import { useDispatch } from "react-redux";
import { useAuthentication } from "../../hooks/useAuthentication";
import Confetti from "react-confetti";

export const Reward = () => {
  const { isLoggedIn } = useAuthentication();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [cashbackAmount, setCashbackAmount] = useState("");

  useEffect(() => {
    // Call the API to get the cashback reward
    // API.getReferral()
    //   .then((response) => {
    //     if (response && response.amount) {
    //       // setCashbackAmount(response.amount);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching reward:", error);
    //   });
  }, []);

  // const handleSubmit = () => {
  //   navigate(ROUTES.PAYMENT_PAGE);
  // };

  return (
    <MainLayout className="register-page">

      {/* <div className="congrats-form"> */}
        <div className="congratulation-container">
          {/* <p className="congratulation-title">Congratulations!</p> */}
          <p className="congratulation-text">
            THE CONSUMER HAS BEEN<br />SUCCESSFULLY REGISTERED
          </p>
          <p className="congratulation-sub-text">Your cashback will be credited <br/>within 48 business hours,<br/>post validation.</p>
        </div>
      {/* </div> */}
    </MainLayout>
  );
};
